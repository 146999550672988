<template lang="pug">
  div
    CCard
      CCardBody
        CRow
          CCol(sm=5)
            h4(class="card-title mb-0") Отчёт по КиЗам

        vuecrud(selectedtable = "wb_excise_goods", :columnsupdated="columnsupdated", :opts="opts", style="margin-top:15px")

</template>

<script>

export default {
  name: 'Partner_Incomes',
  data: function () {
    return {
      show: true,
      isCollapsed: true,

      opts: {
        canAdd: false,
        canEdit: false,
        canDelete: false,
        where: {
        }
      }

    }
  },
  methods: {
    columnsupdated: function (cols) {

      cols.delete('id');
      cols.delete('partner');

      cols.set('inn', {label: "ИНН поставщика", hasFilter: false, });
      cols.set('date', {label: "Дата", hasFilter: false, });
      cols.set('finishedPrice', {label: "Итоговая цена с учётом НДС", hasFilter: false, });
      cols.set('operationTypeId', {label: "Тип операции", hasFilter: false, });
      cols.set('fiscalDt', {label: "Время фискализации", hasFilter: false, });
      cols.set('docNumber', {label: "Номер фискального документа", hasFilter: false, });
      cols.set('fnNumber', {label: "Номер фискального накопителя", hasFilter: false, });
      cols.set('regNumber', {label: "Регистрационный номер ККТ", hasFilter: false, });
      cols.set('excise', {label: "Акциз (КиЗ)", hasFilter: false, });


      return cols;

    },
  }
}
</script>
